import { AuthenticateServiceModule } from './../services/authenticate.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastServiceModule } from '../services/toast.service';
import { RedefinePasswordComponent } from './redefine-password.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModule
    ],
    declarations: [
        RedefinePasswordComponent
    ],
    exports: [
        RedefinePasswordComponent
    ],
    providers: [
        AuthenticateServiceModule,
        ToastServiceModule
    ]
})
export class RedefinePasswordModule { }
