import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Charge } from '../models/charge.model';

@Injectable()
export class ChargesServiceModule {

    constructor(private http: HttpClient) { }

    get(params: string = ''): Observable<Array<Charge>> {
        return this.http.get<Array<Charge>>(`${environment.server}/api/charges/?${params}`);
    }

    show(id: number): Observable<Charge> {
        return this.http.get<Charge>(`${environment.server}/api/charges/${id}`);
    }

    getByTransactionId(transactionId: string): Observable<Charge> {
        return this.http.get<Charge>(`${environment.server}/public/charge/${transactionId}`);
    }

    getByInstallmentReference(installmentReference: string): Observable<Array<Charge>> {
        return this.http.get<Array<Charge>>(`${environment.server}/public/charges/${installmentReference}`);
    }

    generateBillet(id: number, type: string = 'billet') {
        return this.http.get<any>(`${environment.server}/public/charges/${id}/pdf/${type}`);
    }

    generateBilletByReference(installmentReference: string) {
        return this.http.get<any>(`${environment.server}/public/charges/${installmentReference}/print`);
    }

    private put(charge: Charge): Observable<any> {
        return this.http.put<any>(`${environment.server}/api/charges/${charge.id}`, charge);
    }

    private post(charge: Charge): Observable<any> {
        return this.http.post<any>(`${environment.server}/api/charges`, charge);
    }

    payment(charge: Charge): Observable<any> {
        return this.http.post<any>(`${environment.server}/api/charges/payment`, charge);
    }

    persist(charge: Charge): Observable<any> {
        return charge.id ? this.put(charge) : this.post(charge);
    }

    delete(charge: Charge): Observable<any> {
        return this.http.delete<any>(`${environment.server}/api/charges/${charge.id}`);
    }

    resendEmailCharge(charge: Charge): Observable<Array<Charge>> {
        return this.http.get<Array<Charge>>(`${environment.server}/public/charges/${charge.installmentReference}/email`);
    }

}
