import { AuthenticateServiceModule } from 'src/app/services/authenticate.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user.model';
import { ToastServiceModule } from '../services/toast.service';
import { UserServiceModule } from '../services/user.service';

@Component({
  selector: 'app-redefine-password',
  templateUrl: './redefine-password.component.html',
  styleUrls: ['./redefine-password.component.scss']
})
export class RedefinePasswordComponent {
  email = '';
  isTokenValid = false;
  isSendEmail = false;
  submitted = false;
  changed = false;
  sendSuccess = false;
  form: FormGroup;
  formPassword: FormGroup;
  token = '';
  password = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticateServiceModule: AuthenticateServiceModule,
    private toastServiceModule: ToastServiceModule,
    private formBuilder: FormBuilder) {
    this.initForm();
    this.initFormPassword();
    this.route.params.subscribe(result => {
      if (result.param === 'email') {
        this.isSendEmail = true;
        return;
      }

      this.token = result.param;

      this.validateToken();
    });
  }

  validateToken() {
    this.authenticateServiceModule
      .validateResetToken(this.token)
      .subscribe(res => {
        this.isTokenValid = res.success;
      }, err => {
        const { error } = err;
        this.isTokenValid = false;
        this.toastServiceModule.showNotification(
          'warning',
          error.message
        );
        this.router.navigate(['/reset/email']);
      });
  }

  initFormPassword() {
    this.formPassword = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  sendEmail() {
    this.submitted = true;
    if (!this.email.trim()) {
      return;
    }
    this.authenticateServiceModule
      .sendEmailForRedefinePassword(this.email)
      .subscribe((res) => {
        this.sendSuccess = res.success;
        console.log(res);
      }, err => {
        const { error } = err;
        this.toastServiceModule.showNotification('danger', error.message);
      });
  }


  save() {
    this.submitted = true;
    if (this.formPassword.invalid) {
      return;
    }

    this.authenticateServiceModule
      .resetPassword(this.token, this.password)
      .subscribe(res => {
        this.toastServiceModule.showNotification('success', 'Senha alterada com sucesso!');
        this.router.navigate(['/login']);

      }, err => {
        const { error } = err;
        this.toastServiceModule.showNotification('danger', error.message);
      });
  }

}
