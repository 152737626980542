import { Component, HostListener } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticateServiceModule } from './../../services/authenticate.service';
import { Customer } from './../../models/customer.model';
import { StatementServiceModule } from '../../services/statement.service';
import * as moment from 'moment';
import { DATE_FORMAT } from './../dashboard/dashboard.component';
import { ToastServiceModule } from 'src/app/services/toast.service';

defineLocale('pt-br', ptBrLocale);

interface Statements {
  payment_date: string,
  checking_account_id: number,
  count_credit: number,
  credit: number,
  count_debit: number,
  debit: number,
  total: number,
  current_balance: number | null,
  previous_balance: number | null
}

export enum SelectionType {
  single = 'single',
  multi = 'multi',
  multiClick = 'multiClick',
  cell = 'cell',
  checkbox = 'checkbox'
}

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})

export class StatementComponent {
  
  customer = new Customer();
  statements: Statements[] = [];
  dateRange: Array<Date> = [
    moment().subtract(15, 'days').toDate(),
    moment().toDate()
  ];
  toggleDropdown = false;

  bsConfig = {
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    rangeInputFormat: 'DD/MM/YYYY',
    showTodayButton: true,
    customTodayClass: 'bs-datepicker-today-class',
  };

  accumulatedBalance: number;
  
  constructor(
    private localeService: BsLocaleService,
    private spinner: NgxSpinnerService,
    private authenticateServiceModule: AuthenticateServiceModule,
    private statementServiceModule: StatementServiceModule,
    private toastServiceModule: ToastServiceModule
  ){
    this.localeService.use('pt-br');
    this.getCustomer();
  }

  @HostListener('document:mousedown', ['$event'])
  clickout(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if(!target.closest('.dropdown')) {
      this.toggleDropdown = false;
    }
  }

  setToggleDropdown() {
    this.toggleDropdown = !this.toggleDropdown;
  }

  onSelectDateRange(dates) {
    if (!dates) return;

    this.dateRange = dates;

    return this.getStatements();
  }

  resetDateRange(event) {
    event.stopPropagation();

    this.dateRange = [
      moment().subtract(15, 'days').toDate(),
      moment().toDate(),
    ]

    this.getStatements();
  }

  getCustomer() {
    this.spinner.show();
    const token = localStorage.getItem('token');
    this.authenticateServiceModule
      .validate(token)
      .subscribe(
        res => {
          this.spinner.hide();
          this.customer = res.customer;
          this.getStatements();
        }
      );
  }

  getStatements() {
    this.spinner.show();
    this.statementServiceModule.getStatements(
      ...this.getFormatedDateRange()
    )
      .subscribe(
        res => {
          this.spinner.hide();
          this.statements = res;
          this.getAccumulatedBalance(this.statements);
        },
        error => {
          this.spinner.hide();
          console.log(error)
        }
      );
  }

  exportData(extensionType: string) {
    this.spinner.show();
    if (!this.dateRange[0] || !this.dateRange[1]) {
      this.spinner.hide();
      this.toastServiceModule.errorToast(
        'Erro ao exportar dados',
        'É necessário selecionar um período para exportar os dados.'
      );
      return;
    }
 
    this.statementServiceModule
      .exportStatement(...this.getFormatedDateRange(), extensionType)
      .subscribe(
        (res) => {
          this.spinner.hide();
          const data = this.downloadData(res, extensionType);
          this.toggleDropdown = false;
        },
        (err) => {
          this.spinner.hide();
          this.toastServiceModule.errorToast(
            'Erro ao exportar dados',
            'Ocorreu um erro ao exportar os dados. Tente novamente mais tarde.'
          );
          this.toggleDropdown = false;
        }
      );
  }

  downloadData(file, extension: string) {
    const extensionType = extension === 'xlsx' ? 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' : `${extension}`;
    const blob = new Blob([file], {type: `application/${extensionType}`});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `STATEMENT_${moment().format('DDMMYYYYHHmmSS')}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getAccumulatedBalance(statements) {

    this.accumulatedBalance = 0;
    
    for (let i = statements.length - 1; i >= 0; i--) {

      this.accumulatedBalance += parseFloat(statements[i].total);
      statements[i].current_balance = this.accumulatedBalance.toFixed(2);
    }    
    return this.statements;
  }

  getFormatedDateRange() {
    return [
      moment(this.dateRange[0]).format(DATE_FORMAT),
      moment(this.dateRange[1]).format(DATE_FORMAT),
    ] as const;
  }
}
