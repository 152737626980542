import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AppComponent } from './app.component';
import { AppHttpInterceptor } from './app.http-interceptors';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from './auth.guard';
import { ChargesServiceModule } from './services/charges.service';
import { LimitsServiceModule } from './services/limits.service';
import { ToastServiceModule } from './services/toast.service';
import { RedefinePasswordModule } from './redefine-password/redefine-password.module';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { StatementModule } from './pages/statement/statement.module';

registerLocaleData(localePt, 'pt-BR');

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHttpInterceptor,
    multi: true
  }
];

const RECAPTCHA_OPTION = {
  language: 'pt-BR',
  invisible: {
    sitekey: '6LfdzZIbAAAAANZCx2SZG8isfzbRkXq-i-glkX-F'
  },
  normal: {
    sitekey: '6LfdzZIbAAAAANZCx2SZG8isfzbRkXq-i-glkX-F'
  }
};

interface IRecaptchaOption {
  sitekey: string;
  theme?: string;
  type?: string;
  tabindex?: number;
  badge?: string;
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),        
    NgxSpinnerModule,    
    RedefinePasswordModule,
    AuthLayoutModule,
    StatementModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0, 189, 99)',
      switchColor: '#80FFA2',
      defaultBgColor: '#00ACFF',
      defaultBoColor: '#476EFF',
      checkedLabel: 'SIM',
      uncheckedLabel: 'NÃO'
    }),
    ReCaptchaModule.forRoot(RECAPTCHA_OPTION)
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: LOCALE_ID,
      useValue: 'pt-br'
    },
    AuthGuard,
    ToastServiceModule,
    ChargesServiceModule,
    LimitsServiceModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
