import { Customer } from './customer.model';

export class User {
    id: number;
    name: string;
    email: string;
    phone: string;
    password: string;
    newPassword: string;
    meta: string;
    customer?: Customer;
    type?: number;
    document: string;

    constructor() {
        this.type = 0;
    }
}
