import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticateServiceModule } from './services/authenticate.service';
import { ToastServiceModule } from './services/toast.service';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private toastServiceModule: ToastServiceModule,
        private router: Router,
        private authenticateServiceModule: AuthenticateServiceModule
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        this.authenticateServiceModule.redirectUrl = state.url;
        const token = localStorage.getItem('token');
        if (token !== null) {
            return true;
        }
        this.router.navigate(['/login']);
        this.toastServiceModule.showNotification('warning', 'Ops, você precisa fazer login!');
        return false;
    }
}
