import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { LimitCycle, LimitCycleNames, LimitFeatureNames } from './../models/limits.model';
@Injectable()
export class LimitsServiceModule {

    constructor(private http: HttpClient) { }

    getCashOutFeatureLimits(id: number, featureName: LimitFeatureNames) {
      return this.http.get<LimitCycle[]>(
        `${environment.server}/limits/${id}/CashOut/features/${featureName}`
      );
    }

    updateCashOutFeatureLimit(
      id: number,
      featureName: LimitFeatureNames,
      cycleType: LimitCycleNames,
      newLimit: number,
    ) {
      return this.http.post<{
        documentNumber: number;
        featureName: LimitFeatureNames;
        cycleType: LimitCycleNames;
        levelType: string;
        amount: number;
      }>(
        `${environment.server}/limits/${id}`,
        {
          featureName,
          cycleType,
          levelType: 'account',
          amount: newLimit,
        }
      );
    }
}
