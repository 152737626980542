import { Routes } from '@angular/router';
import { RedefinePasswordComponent } from 'src/app/redefine-password/redefine-password.component';
import { LoginComponent } from '../../pages/login/login.component';
import { AuthLayoutComponent } from './auth-layout.component';


export const AuthLayoutRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },      
    ]
  }
];
// {
//   path: '',
//   component: AdminLayoutComponent,
//   canActivate: [AdminCanActivate],
//   children: [
//     { path: '', component: DashboardComponent },
//     { path: 'dashboard', component: DashboardComponent },
//     { path: 'customers', component: CustomersComponent },
//     { path: 'customer/:id', component: CustomerComponent },
//     { path: 'payment-links', component: PaymentLinksComponent },
//     { path: 'payment-link/:id', component: PaymentLinkComponent },
//     { path: 'charges', component: ChargesComponent },
//     { path: 'charge/:id', component: ChargeComponent },
//     { path: 'user-profile', component: UserProfileComponent },
//     { path: 'tables', component: TablesComponent },
//     { path: 'icons', component: IconsComponent },
//     { path: 'maps', component: MapsComponent },
//     { path: 'profile', component: ProfileComponent }
//   ]
// }
