import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";

@Injectable()
export class StatementServiceModule {
  constructor(private http: HttpClient) { }

  getStatements(startDate?: string, endDate?: string): Observable<any> {
    let query = '';
    query = `startDate=${startDate}&endDate=${endDate}`
  
    return this.http.get<any>(
      `${environment.server}/admin/statement?${query}`
    );
  }

  exportStatement(startDate?: string, endDate?: string, extensionType?: string): Observable<any> {
    let params = '';
    params = `startDate=${startDate}&endDate=${endDate}&extensionType=${extensionType}`
    return this.http.get<any>(
      `${environment.server}/admin/export-statement?${params}`,
      { responseType: "blob" as "json" }
    );
  }
}