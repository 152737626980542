import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticateServiceModule } from './../../services/authenticate.service';
import { ToastServiceModule } from './../../services/toast.service';
import { User } from './../../models/user.model';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  banking: any = environment.banking;
  submitted = false;
  formLogin: FormGroup;
  user: User = new User();
  emailRegEx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

  constructor(
    private toastServiceModule: ToastServiceModule,
    private authenticateServiceModule: AuthenticateServiceModule,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinnerServiceModule: NgxSpinnerService) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formLogin = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy() {
  }

  onSubmit() {
    this.submitted = true;

    if (this.formLogin.invalid) {
      return;
    }

    this.spinnerServiceModule.show();
    this.authenticateServiceModule.signIn(this.user)
      .subscribe(res => {
        if (!res.error) {
          const { user } = res;
          this.spinnerServiceModule.hide();
          localStorage.setItem('token', res.token);
          if (this.authenticateServiceModule.redirectUrl) {
            this.router.navigate([this.authenticateServiceModule.redirectUrl]);
          } else {
            this.router.navigate(['/admin/dashboard']);
          }
        } else {
          this.spinnerServiceModule.hide();
          this.toastServiceModule.showNotification('warning', res.message);
        }
      }, error => {
        console.log(error.error.message);
        this.spinnerServiceModule.hide();
        this.toastServiceModule.showNotification('warning', error.error.message, 'Atenção');
      });
  }

}
