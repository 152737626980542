export class Bank {

  id: number;
  name: string;
  ispb: string;

  constructor() {

  }

}
