export const environment = {
  server: 'https://api-pix.cashpaybrasil.com.br',
  app: 'https://pix-admin.cashpaybrasil.com.br',
  production: true,
  banking: {
    name: 'Cashpay',
    email: 'contato@cashpaybrasil.com.br',
    site: 'https://cashpaybrasil.com.br',
    termsOfUse: 'https://cashpaybrasil.com.br',
  }
};
