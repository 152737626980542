import { CheckingAccount } from './checkingAccount.model';
import { CustomerDocument } from './customer-document';
import { FeeSettings } from './fee-setting.model';
import { User } from './user.model';
import { Webhook } from './webhok.model';

export class Customer {
  id: number;
  name: string;
  tradingName: string;
  email: string;
  phoneNumber: string;
  website: string;
  document: string;
  type: string;
  addressLine1: string;
  addressLine2: string;
  neighborhood: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  isActive: Boolean;
  isInQueue: Boolean;
  inQueueAt: Date;
  documents: CustomerDocument[];
  meta: any;
  deviceInfo: any;
  marketPlaceId: string;
  checkingAccount: CheckingAccount;
  withdrawCheckingAccount: CheckingAccount;
  feeSettings: FeeSettings;
  status: number;
  statusDescription: string;
  logo: string;
  user: User;
  isMarketPlace: Boolean;
  billetLimitAmount: number;
  countryPhoneCode: string;
  businessSize: string;
  transactionPassword: string;
  privateKey: string;
  publicKey: string;
  webhooks: Webhook[];
  description: any;
  paymentGatewayStatus: any;
  createdAt: any;
  pixKeyType: string;
  pixKeyValue: string;
  hasTransactionPassword: boolean;


  constructor() {
    this.checkingAccount = new CheckingAccount();
    this.withdrawCheckingAccount = new CheckingAccount();
    this.feeSettings = new FeeSettings();
    this.documents = [];
    this.webhooks = [];
    this.hasTransactionPassword = false;
  }
}
