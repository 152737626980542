import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RedefinePasswordComponent } from './redefine-password/redefine-password.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

const routes: Routes = [        
  {
    path: 'reset/:param',
    component: AuthLayoutComponent,
    children: [
      { path: '', component: RedefinePasswordComponent },
    ]
  },
  {
    path: 'login',
    loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
  },
  {
    path: 'admin',
    loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
  },  
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule { }
