import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Component, OnInit } from '@angular/core';
import { DashboardsServiceModule } from './../../services/dashboards.service';
import Chart from 'chart.js/auto';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customer } from '../..//models/customer.model';
import { User } from '../..//models/user.model';
import { AuthenticateServiceModule } from '../../services/authenticate.service';
import * as moment from 'moment';


export const DATE_FORMAT = `YYYY-MM-DD`;

type ChartSectionConfig = {
  label: string;
  amount: number;
  backgroundColor: string;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  salesChart: any;
  amounts: any = {
    createdAmount: 0,
    createdCount: 0,
    paidAmount: 0,
    paidCount: 0,
    paidAvarage: 0,
    paymentRate: 0
  }

  dateRange: Array<Date> = [
    moment().clone().subtract(5, 'days').startOf('day').toDate(),
    moment().clone().endOf('day').toDate()
  ];

  chartsData: {
    accountSituation: ChartSectionConfig[],
    cashIn: ChartSectionConfig[],
    cashOut: ChartSectionConfig[],
  };

  bsConfig = {
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    rangeInputFormat: 'ddd DD MMM/YYYY',
    showTodayButton: true,
    customTodayClass: 'bs-datepicker-today-class'
  };

  user: User = new User();
  customer: Customer = new Customer();

  constructor(
    private dashboardService: DashboardsServiceModule,
    private spinner: NgxSpinnerService,
    private authenticateServiceModule: AuthenticateServiceModule,
    private localeService: BsLocaleService,
  ) {
    this.localeService.use('pt-BR');

    moment.locale('pt-BR')

    this.chartsData = {
      accountSituation: [],
      cashIn: [],
      cashOut: [],
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.getCustomer();
    this.setupChart();
    this.getTotalSales(this.dateRange);
  }

  getTotalSales(dates: Array<Date>) {
    this.spinner.show();
    const [startDate, endDate] = dates;

    this.dashboardService
      .getTotalSales(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
      ).
      subscribe(res => {
        this.amounts = res;
        this.spinner.hide();
        this.getChartSales(dates);
      }, error => {
        this.spinner.hide();
      })
  }

  setupChart() {
    const labels = [];
    const emitidas = [];

    moment.locale('pt-BR');

    const chartSales = document.getElementById('chart-sales') as HTMLCanvasElement;

    this.salesChart = new Chart(chartSales, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: "Pagos",
            data: [],
            fill: 'start',
            borderColor: '#2dce89',
            backgroundColor: '#2dce8988',
          },
          {
            label: "Gerados",
            fill: 'start',
            data: [],
            borderColor: '#287dce',
            backgroundColor: '#287dce44',
          },
        ]
      },
      options: {
        aspectRatio: 4.5,
        scales: {
          y: {
            stacked: true
          },
        },
        plugins: {
          filler: {
            propagate: true
          },
          tooltip: {
            // callbacks: {
            //   label: (item) => {
            //     return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item.raw));
            //   },
            // }
          }
        },
        interaction: {
          intersect: true,
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
      },
    });
  }

  updateChart(labels, datasets) {
    const [gerados, pagos] = datasets;
    this.salesChart.data.labels = labels;
    this.salesChart.data.datasets[0].data = pagos;
    this.salesChart.data.datasets[1].data = gerados;
    this.salesChart.update();
  }

  getChartSales(dates: Array<Date>) {
    const [startDate, endDate] = dates;

    this.dashboardService
      .getTotalChartSales(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
      )
      .subscribe(res => {
        const { datasets, labels } = res;
        this.updateChart(labels, datasets);
      }, error => console.log(error));
  }

  onSelectDateRange(dates) {
    if (!dates) {
      return;
    }

    const [inicio, fim] = this.dateRange;

    const a = moment(dates[0]).format(DATE_FORMAT) != moment(inicio).format(DATE_FORMAT);
    const b = moment(dates[1]).format(DATE_FORMAT) != moment(fim).format(DATE_FORMAT);

    if (a || b) {
      console.log('Datas Mudaram');
      this.getTotalSales(dates);
    }
  }


  getCustomer() {
    const token = localStorage.getItem('token');
    this.authenticateServiceModule
      .validate(token)
      .subscribe(
        res => {
          const customer = res.customer;

          delete res.customer;

          this.user = res;
        });
  }

  getCurrencyString = (amount: number) => {
    const realBRFormatter = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 });
    return `R$ ${realBRFormatter.format(amount)}`;
  }

  dynamicColors() {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
}
