import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { ToastServiceModule } from './services/toast.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private toastServiceModule: ToastServiceModule,
        private router: Router
    ) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token) {
            const secureReq = req.clone({
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${token}`
                })
            });
            return next.handle(secureReq).do((event: any) => {
                if (event instanceof HttpResponse) {
                    if ([401, 403].includes(event.status)) {
                        this.login();
                    }
                }
            }, error => {                
                if ([401, 403].includes(error.status)) {
                    this.login();
                }
            });
        }
        return next.handle(req);
    }

    private login() {
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
    }
}
