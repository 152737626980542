import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DashboardsServiceModule {

  constructor(private http: HttpClient) { }

  index(): Observable<any> {
    return this.http.get<any>(`${environment.server}/dashboard`);
  }

  getTotalSales(startDate: string, endDate: string) {
    return this.http.get<any>(`${environment.server}/dashboard/total-sales?startDate=${startDate}&endDate=${endDate}`);
  }

  getTotalChartSales(startDate: string, endDate: string) {
    return this.http.get<any>(`${environment.server}/dashboard/total-chart-sales?startDate=${startDate}&endDate=${endDate}`);
  }

  getChartData(): Observable<any> {
    const fakeResponse = {
      accountSituation: [
        { label: 'Contas reprovadas', amount: 100, backgroundColor: '#EB9486' },
        { label: 'Contas aprovadas', amount: 560, backgroundColor: '#cae7b9' },
        { label: 'Contas pendentes', amount: 30, backgroundColor: '#F3DE8A' },
      ],
      cashIn: [
        { label: 'Pix', amount: 400000, backgroundColor: '#7E7F9A' },
        { label: 'Outros', amount: 100000, backgroundColor: '#97A' }
      ],
      cashOut: [
        { label: 'Pix', amount: 600000.5, backgroundColor: '#214F4B' },
        { label: 'Ted', amount: 20000.05, backgroundColor: '#78C3FB' },
        { label: 'Outros', amount: 100000, backgroundColor: '#8D5A97' }
      ],
    };
    return this.http.get<any>(`${environment.server}/dashboard`);
  }
}
