import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginModule } from '../../pages/login/login.module';
import { AuthLayoutComponent } from './auth-layout.component';
import { AuthLayoutRoutes } from './auth-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RedefinePasswordModule } from 'src/app/redefine-password/redefine-password.module';


@NgModule({
  imports: [
    RouterModule.forChild(AuthLayoutRoutes),
    CommonModule,
    FormsModule,
    LoginModule,
    RedefinePasswordModule,
    NgbModule
  ],
  exports: [
    AuthLayoutComponent
  ],
  declarations: [
    AuthLayoutComponent,    
  ]
})
export class AuthLayoutModule { }
