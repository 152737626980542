import { Bank } from './bank.model';

export class CheckingAccount {

  id: number;
  type: string;
  customer: string;
  holderName: string;
  document: string;
  description: string;
  agency: string;
  accountNumber: string;
  bankId: number;
  bank: Bank;

  constructor() {
    this.type = 'PAYMENT_ACCOUNT';
    this.bank = new Bank();
  }

}
