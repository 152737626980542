import { Customer } from "./customer.model";

export class FeeSettings {
    description: string;
    isDefault: Boolean;
    id: number;
    tedFee: number;
    pixFee: number;
    billetFee: number;
    accountMonthlyFee: number;
    customers?: Array<Customer>;

    constructor() {
        this.isDefault = false;
        this.description = '';
        this.customers = [];
    }
}