import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatementComponent } from './statement.component';
import { NgxCurrencyModule } from "ngx-currency";
import { AuthenticateServiceModule } from './../../services/authenticate.service';
import { StatementServiceModule } from '../../services/statement.service';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: ".",
    nullable: true
};

@NgModule({
    imports: [
        CommonModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        FormsModule,
        BsDatepickerModule.forRoot(),
    ],
    declarations: [ StatementComponent ],
    exports: [ ],
    providers: [ AuthenticateServiceModule, StatementServiceModule ]
})

export class StatementModule { }